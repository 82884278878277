export const environment = {
  production: false,
  gtcApiUrl: 'https://api.qa01e.gcp.ford.com/purchasinglegacy',
  perlMigrationApiUrl: 'https://api.qa01e.gcp.ford.com/perlmigration',
  paarsWebApiUrl: 'https://api-qat.erp.ford.com/paarsweb',
  openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2',
  openidClientId: 'urn:fspgcp:clientid:web_fsp_adfs:qa',
  openidResource: 'urn:fspgcp:resource:web_fsp_adfs:qa',
  openidRedirectUrl: 'https://fsp-qa-angular-257443053019.us-central1.run.app/oauth'
};
